section#sobre{
	.indution{
		background-color: $base-gray;
		color: #fff;
		display: flex;
		align-items: stretch;
		.article{
			&:first-child{
				background-image: url(../images/imagem_A-INDUTION_2048px_RGB_72dpi.png);
				background-repeat: no-repeat;
				background-size: cover;
				background-position: top center;
			}
			&:last-child{
				text-align: left;
				padding: $grid-gutter-width-base*3;
				h1{width: calc(100% - 60px);}
				p{
					font-size: 2rem;
					font-weight: 300;
					line-height: 2.5em;
				}
			}
			@include media-breakpoint-down(sm) {
				z-index: 2;
				background-color: rgba($base-gray, .8);
				h1{width: auto!important}
				p{font-size: 1.2rem!important}
			}
		}
		@include media-breakpoint-down(sm) {
			position: relative;
			&:before{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 1;
				background-image: url(../images/imagem_A-INDUTION_2048px_RGB_72dpi.png);
				background-size: cover;
			}
		}
	}
	.info{
		*{text-align: left!important;}
		h2{
			font-size: 2.5rem;
			color: $base-green;
			font-weight: 300;
			height: 5rem;
			@include media-breakpoint-down(sm) { 
				font-size: 1.5rem;
				height: auto;
				margin-bottom: .5em;
			}
		}
		p{
			margin-top: $grid-gutter-width-base;
			font-size: 1.4rem;
			line-height: 2;
			font-weight: 300;
			@include media-breakpoint-down(sm) { font-size: 1rem!important; }
		}
		p.logo{
			text-align: center!important;
			margin: 0;
			img{margin: 0}
		}
		img{
			margin: $grid-gutter-width-base 0;
			width: 35%;
			height: auto;
			@include media-breakpoint-down(sm) {
				height: 50px;
				width: auto;
			}
		}
	}
}