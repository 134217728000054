section#parceiros{
	min-height: initial!important;
	padding: $grid-gutter-width-base*3;
	@include media-breakpoint-down(sm) { padding: $grid-gutter-width-base }
	h1{width: auto;}
	.parceiros{
		display: flex;
		justify-content: space-between;
		img{ 
			height: 30px;
			width: auto;
		}
		@include media-breakpoint-down(sm) {
			text-align: center;
			flex-direction: column;
			img{margin: $grid-gutter-width-base 0}
		}
	}
	.clientes img{height: 50px;}
}