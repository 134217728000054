section#home{
	min-height: 100vh;
	padding: 0;
	display: flex;
	flex-direction: column;
	.social-icons-mobile{
		background-color: $base-green;
		display: flex;
		align-items: center;
		justify-content: center;
		a{
			width: 25px;
			height: 25px;
			margin: $grid-gutter-width-base/2;
			color: #fff;
			.icon{
				width: 100%;
				height: 100%;
			}
		}
	}
	.hero{
		background-image: url(../images/imagem_HOME_2048px_RGB_72dpi.png);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: top center;
		flex-grow: 1;
		flex-direction: column-reverse;
		min-height: 80vh;
		display: flex;
		@include media-breakpoint-down(lg) {background-image: url(../images/imagem_HOME_2048px_RGB_72dpi.png);}
		@include media-breakpoint-down(md) {background-image: url(../images/imagem_HOME_1024px_RGB_72dpi.png);}
		@include media-breakpoint-down(sm) {
			background-position: -120px -40px;
			background-size: initial;
			min-height: initial;
		}
		.col-md-10{
		    flex-direction: row-reverse;
    		display: flex;
			.hero-cta{
				border-radius: $grid-gutter-width-base;
				color: #fff;
				font-weight: 300;
				font-size: 2em;
				background-color: rgb(252, 175, 23);
				box-shadow: 0px 2px 4px 0 rgba(0,0,0,.2);
				b{font-weight: 500;}
				&:hover, &:focus, &:active{
					box-shadow: 0px 5px 10px 0 rgba(0,0,0,.2);
				}
			}
		}
	}
	.title{
		color: $base-green;
		padding: $grid-gutter-width-base 0;
		h1{
			font-weight: 500;
			font-size: 4em;
			@include media-breakpoint-down(sm) {
				font-size: 2rem;
			}
		}
	}
	.subtitle{
		color: #fff;
		background-color: $base-green;
		padding: $grid-gutter-width-base*2 0;
		h2{
			font-weight: 300;
			@include media-breakpoint-down(sm) {
				font-size: 1rem;
				line-height: 1.5!important;
			}
		}
	}
	.title, .subtitle{
		@include media-breakpoint-down(sm) {padding: $grid-gutter-width-base/2 0;}
		h1, h2{
			text-align: center;
			text-transform: uppercase;
			margin: 0;
			line-height: 1;
		}
	}
}