footer{
	background-color: $base-green;
	text-align: center;
	color: #fff;
	padding: $grid-gutter-width-base;
	font-weight: 300;
	.copyright{font-size: .8rem;}
	.social-icons{
		margin: $grid-gutter-width-base/2;
		.icon{
			display: inline-block;
			width: 30px;
			height: 30px;
			margin: $grid-gutter-width-base/2;
			color: #fff;
		}
	}
}