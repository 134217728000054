section#contato{
	background-color: $base-gray;
	h1{
		color: #fff;
		border-color: #fff;
	}
	h5, p, a{
		text-align: right;
		color: #fff;
		@include media-breakpoint-down(sm) {text-align: center;}
	}
	p{font-weight: 300;}
	a{text-decoration: none!important;}
}