header{
	position: fixed;
	z-index: 1000;
	width: 100%;
	box-shadow: 0px 2px 4px 0 rgba(0,0,0,.2);
	.nav-top{
		background-color: #fff;
		.brand-logo{
			display: block;
			width: 100%;
			height: 100%;
			padding: $grid-gutter-width-base/2;
		}
		@include media-breakpoint-down(md) {
			position: absolute;
			width: 100%;
			box-shadow: 0px 2px 4px 0 rgba(0,0,0,.2);
			z-index: 5;
			.brand-logo{
				max-width: 150px;
				height: 60px;
				padding: $grid-gutter-width-base/4 0;
				svg{height: 50px}
			}
		}
		.menu-anchor{
		    position: absolute;
		    background-color: #16b282;
		    right: 10px;
		    top: 50%;
		    transform: translateY(-50%);
		    width: 30px;
		    height: 30px;
		    display: flex;
		    justify-content: center;
		    align-items: center;
		    border-radius: 5px;
			i{
				font-size: 1.5rem;
				color: #fff;
			}
		    &:focus, &:active{text-decoration: none;}
		}
		.social-icons{
			align-self: center;
			margin-right: calc(100%/12);
			text-align: right;
			a{
				margin: 0 $grid-gutter-width-base/4;
				display: inline-block;
				.icon{
					color: $base-green;
					width: 25px;
					height: 25px;
				}
			}
			@include media-breakpoint-down(md) {
				display: none;
				align-items: center;
				align-self: initial;
				justify-content: center;
				padding: $grid-gutter-width-base/2;
				margin: 0;
				background-color: $base-green;
				a {
					margin: 0 $grid-gutter-width-base/2;
					.icon{
						color: #fff;
						width: 1.5rem;
						height: 1.5rem;
					}
				}
			}
		}
	}
	.nav-bottom{
		background-color: $base-green;
		ul.menu-top{
			list-style-type: none;
			display: flex;
			justify-content: flex-end;
			margin-right: calc(100%/12);
			margin-bottom: 0;
			li{
				padding: $grid-gutter-width-base/2 $grid-gutter-width-base;
				a{
					color: #fff;
					text-transform: uppercase;
					font-weight: 300;
					&:hover, &:focus, &:active{
						text-decoration: none;
						font-weight: 700;
					}
				}
				&:last-child{
					padding-right: 0;
				}
			}
		}

		// Menu Mobile

		@include media-breakpoint-down(sm) {
			display: none;
			position: absolute;
			z-index: 1;
			top: 0;
			background-color: #fff;
			border-radius: $grid-gutter-width-base;
			box-shadow: 0px 2px 4px 0 rgba(0,0,0,.2);
			width: calc(100vw - 40px);
			margin: 42px 20px 0;
			padding-top: 20px;
			ul.menu-top{
				margin: 0;
				display: flex;
				flex-direction: column;
				padding: 0;
				li{
					text-align: center;
					padding: $grid-gutter-width-base/2!important;
					a{
						color: $base-green;
						font-weight: 700;
					}
				}
			}
		}
	}
}


