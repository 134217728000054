$base-font-family: "Roboto", Helvetica, Arial, sans-serif;
$base-font-size: 16px;
$base-font-weight: 400;
$small-font-size: $base-font-size * 0.875;
$base-line-height: 1.5;

$base-green: rgb(22, 178, 130);
$base-gray: rgb(71, 69, 74);

$text-color: $base-gray;
$border-color: #CCCCCC;

$grey-color: $base-gray;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);