// Bootstrap v4
@import 'bootstrap-variables';
@import 'bootstrap';

@import 'variables';

@import 'header';
@import 'home';
@import 'sobre';
@import 'solucoes';
@import 'demo';
@import 'parceiros';
@import 'clientes';
@import 'contato';
@import 'footer';


// General Styles
body{
	color: $base-gray;
	max-width: 100vw;
}
svg{
	width: inherit;
	height: inherit;
	fill: currentColor;
}
.icon svg *, {fill: inherit!important;}
.content-wrapper{
	max-width: 100vw;
	overflow: hidden;
	@include media-breakpoint-down(sm) { padding-top: 62px}
	section{
		.article{
			padding-top: $grid-gutter-width-base*2;
			padding-bottom: $grid-gutter-width-base*2;
			text-align: center;
			h1{
				text-transform: uppercase;
				display: inline-block;
				font-size: 2.5rem;
				font-weight: 500;
				letter-spacing: .1em;
				margin-bottom: $grid-gutter-width-base*2;
				line-height: 1;
				color: $base-green;
				padding: $grid-gutter-width-base/2;
				width: 60%;
				border-bottom: .075em solid $base-green;
			}
			p{
				font-size: 1.4rem;
				line-height: 2;
				font-weight: 300;	
			}
			@include media-breakpoint-down(sm) {
				padding-top: $grid-gutter-width-base;
				padding-bottom: $grid-gutter-width-base;
				h1 {
					font-size: 1.8rem!important;
					letter-spacing: .05em!important;
					margin-bottom: $grid-gutter-width-base;
				}
				p{
					text-align: left;
					font-size: 1rem!important;
					font-weight: 300!important;
				}
			}
		}
	}
}