section#demo{
	min-height: 100vh;
	background-image: url(../images/imagem_DEMONSTRACAO_4096px_RGB_72dpi.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	@include media-breakpoint-down(lg) {background-image: url(../images/imagem_DEMONSTRACAO_2048px_RGB_72dpi.png);}
	@include media-breakpoint-down(md) {background-image: url(../images/imagem_DEMONSTRACAO_1024px_RGB_72dpi.png);}
	@include media-breakpoint-down(sm) {
		margin-top: $grid-gutter-width-base;
}
	h1{
		color: #fff;
		border-color: #fff;
		@include media-breakpoint-down(sm) {width: auto;}
	}
	.subtitle{
		display: block;
		text-align: center;
		font-size: 1.2rem;
		font-weight: 300;
		line-height: 1;
		margin: -1.5rem 0 3rem;
		color: #fff;
		@include media-breakpoint-down(sm) {
			font-size: 1rem;
			margin: -.5rem 0 0;
		}
	}
	form{
		text-align: center;
		padding-bottom: $grid-gutter-width-base;
		input{
			background-color: rgba(255,255,255,0.75);
			border-radius: 0;
			font-size: 1.3rem;
			font-weight: 300;
			padding: $grid-gutter-width-base;
			border: none;
			height: 70px;
			margin: $grid-gutter-width-base 0;
			@include media-breakpoint-down(sm) {
				height: 50px;
				margin: $grid-gutter-width-base/2 0;
				font-size: 1rem;
			}
			&::-webkit-input-placeholder{
				color: $base-green;
			}
		}
		button{
			height: 80px;
			padding: $grid-gutter-width-base $grid-gutter-width-base*2;
			color: $base-green;
			background-color: #fff;
			margin: $grid-gutter-width-base*2;
			border-radius: $grid-gutter-width-base;
			font-size: 1.3rem;
			font-weight: 700;
			cursor: pointer;
			@include media-breakpoint-down(sm) {
				height: 60px;
				font-size: 1.2rem;
			}
		}
	}
}