section#clientes{
	min-height: initial!important;
	padding: $grid-gutter-width-base*3;
	@include media-breakpoint-down(sm) { padding: $grid-gutter-width-base }
	h1{width: auto;}
	.clientes{
		width: 100%;
		.slick-slider{
			.slick-arrow{
				position: absolute;
				color: $base-green!important;
				font-size: 1.5rem;
				&.fa-chevron-left{
					left: -20px;
					top: 50%;
					transform: translateY(-50%);
				}
				&.fa-chevron-right{
					right: -20px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
			.slick-slide{
				height: 50px;
				background-repeat: no-repeat;
				background-size: contain;
				background-position: top center;
				&.AIS{background-image: url(../images/logotipo_AIS.png);}
				&.Bancorbras{background-image: url(../images/logotipo_Bancorbras.png);}
				&.FOX_Engenharia-Consultoria{background-image: url(../images/logotipo_FOX_Engenharia-Consultoria.png);}
			}
		}
	}
}