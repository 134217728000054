section#solucoes{
	h1 + p{text-align: center;}
	.celular{
		background-image: url(../images/imagem_CELULAR_1024px_72dpi.png);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: top center;
		height: 80vh;
	}
	.solution-cards{
		@include media-breakpoint-down(sm) { .col-md-4{margin: 10px 0} }
		.flip-container {
			perspective: 1000px;
			height: 500px;
			@include media-breakpoint-down(sm) { height: 420px}
		}
		.flipper.flip {
			transform: rotateY(180deg);
		}
		.front{background-color: $base-green;}
		.back{background-color: $base-gray;}
		.front, .back {
			border-radius: $grid-gutter-width-base;
			height: 500px;
			max-width: 100%; // fix safari
			display: flex;
			flex-direction: column;
			padding: $grid-gutter-width-base;
			align-items: center;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			
			.icon{
				height: 50px;
				color: #fff;
			}
			h2, h5, p, a{
				margin-top: $grid-gutter-width-base;
				text-align: center;
				color: #fff;
			}
			p{
				line-height: 2;
				font-weight: 300;
			}
			a.flip-btn{
				margin-top: auto;
				text-decoration: none!important;
				&:hover{font-weight: 700}
			}
			ul{
				padding: $grid-gutter-width-base;
				li{
					font-weight: 300;
					color: #fff;
				}
			}
			h5{
				font-weight: 300;
				span{
					font-weight: 700
				}
			}
			@include media-breakpoint-down(sm) { 
				height: 420px;
				margin: 0 $grid-gutter-width-base;
				> .icon{display: none;}
				h2, h5, p, a{margin: 0}
				p{
					font-size: 1em;
					line-height: initial;
					margin-top: $grid-gutter-width-base;
				}
				ul{
					padding: $grid-gutter-width-base/2;
					padding-bottom: 0;
					li{
						font-size: .9em;
						line-height: initial;
					}
				}
				a > .icon{height: 30px;}
			}
		}

		/* flip speed goes here */
		.flipper {
			transition: 0.6s;
			transform-style: preserve-3d;

			position: relative;
		}

		/* hide back of pane during swap */
		.front, .back {
			backface-visibility: hidden;
		}

		/* front pane, placed above back */
		.front {
			z-index: 2;
			/* for firefox 31 */
			transform: rotateY(0deg);
		}

		/* back, initially hidden pane */
		.back {
			transform: rotateY(180deg);
		}
	}
	.subtitle{
		display: block;
		text-align: center;
		font-size: 1.5rem;
		line-height: 1;
		margin: -1.5rem 0 3rem;
		color: $base-green;
		@include media-breakpoint-down(sm) {
			font-size: 1.2rem;
			margin: -0.8rem 0 1.2rem;
		}
	}
	.tag{
		margin: $grid-gutter-width-base 0;
		@include media-breakpoint-down(sm) {margin: 0;}
		.tag-img{
			text-align: center;
			img, svg{
				width: 100%;
				height: auto;
				max-height: 300px;
				@include media-breakpoint-down(sm) {
					width: 50%
				}
			}
		}
		.tag-text{
			padding-left: $grid-gutter-width-base*4;
			h2{color: $base-green}
			ul{
				padding-left: $grid-gutter-width-base/2;
				margin: $grid-gutter-width-base;
				li{
					font-size: 1.2rem;
					font-weight: 300;
					line-height: 2;
				}
			}
			@include media-breakpoint-down(sm) {
				padding: $grid-gutter-width-base 0 0;
				*{text-align: center;}
				h2{font-size: 1.5rem;}
				ul {
					list-style-type: none;
					padding: 0;
					margin: 0;
					li{
						font-size: 1rem;
						line-height: 1.5;
					}
				}
			}
		}
		&.invert{
			flex-direction:row-reverse;
			.tag-text{padding-left: 0}
		}
	}
	.linha-dots{
		width: 100%;
		height: 100px;
		background-image: url(../images/linha-dots.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		&.invert{transform: rotateY(180deg)}
		@include media-breakpoint-down(sm) {
			margin: $grid-gutter-width-base/2 0;
			height: 100px;
			background-image: url(../images/linha-dots-v.png);
		}
	}
}